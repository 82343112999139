import * as React from 'react';
import { Box } from '@mui/material';
import { RoundedButton } from '../../../../../components/RoundedButton';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';

import appRoute from '../../../../../../utils/appRoute';
import { useHistory } from 'react-router-dom';
import { SignUpForRoomLayout } from '../../layout';
import { useQuery } from 'hooks';
import { ConfirmSignUpModal } from '../../modals/confirmSignUpModal';
import { selectSignUpForRoom } from '../../slice/selectors';
import { useSelector } from 'react-redux';

const REACT_APP_GOOGLE_CLIENTID = process.env.REACT_APP_GOOGLE_CLIENTID;
const REACT_APP_FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

interface SignUpForRoomHomeProps {}

export function SignUpForRoomHome(props: SignUpForRoomHomeProps) {
  const history = useHistory();
  const query = useQuery();
  const { isSigningUp } = useSelector(selectSignUpForRoom);
  const [socialModalOpen, setSocialModalOpen] = React.useState(false);
  const [disabledProviders, setDisabledProviders] = React.useState<string[]>(
    [],
  );
  const [socialData, setSocialData] =
    React.useState<Record<string, string | undefined>>();

  const goToPage = (path: string) => {
    history.push({ pathname: path, search: query.toString() });
  };

  const resetSocialSignIn = () => {
    setSocialModalOpen(false);
    setSocialData({});
  };

  const responseFacebook = response => {
    if (response.email) {
      const fullName = response.name.split(' ');

      setSocialData({
        firstName: fullName[0],
        lastName: fullName[1] ?? '',
        email: response.email,
        provider: 'facebook',
        providerKey: response.id,
      });
      setSocialModalOpen(true);
    } else {
      alert('Could not get required profile information.');
    }
  };

  const handleSuccessResponseFromGoogle = response => {
    if (response.profileObj?.email) {
      setSocialData({
        firstName: response.profileObj.givenName,
        lastName: response.profileObj.familyName,
        email: response.profileObj.email,
        provider: 'google',
        providerKey: response.googleId,
      });
      setSocialModalOpen(true);
    } else {
      alert('Could not get required profile information.');
    }
  };

  const handleErrorResponseFromGoogle = response => {
    // User closed the popup, do nothing
    if (response.error === 'popup_closed_by_user') {
      return;
    }

    console.error('Could not enable google login', response);
    // Google initialization failed, usually because of wrong clientId/disabled domain
    if (response.error === 'idpiframe_initialization_failed') {
      setDisabledProviders([...disabledProviders, 'google']);
    } else {
      alert('Social login failed. Please try again.');
    }
  };

  return (
    <SignUpForRoomLayout title="Create Your Account">
      <Box sx={{ mb: 4, textAlign: 'center' }}>
        <RoundedButton
          data-testid="sign-up-button-sign-up"
          className="fullWidth"
          onClick={() => goToPage(appRoute.signUpForRoomEmail())}
          sx={{ mb: 2 }}
        >
          Sign Up with Email
        </RoundedButton>

        <FacebookLogin
          appId={REACT_APP_FACEBOOK_APP_ID}
          callback={responseFacebook}
          fields="name,email"
          render={renderProps => (
            <RoundedButton
              data-testid="log-in-button-log-in-with-facebook"
              onClick={e => {
                resetSocialSignIn();
                renderProps.onClick(e);
              }}
              className="facebookLogin fullWidth"
              loading={isSigningUp}
              disabled={disabledProviders.includes('facebook')}
              sx={{ mb: 2 }}
            >
              Sign up with Facebook
            </RoundedButton>
          )}
        />

        <GoogleLogin
          clientId={REACT_APP_GOOGLE_CLIENTID}
          render={renderProps => (
            <RoundedButton
              onClick={() => {
                resetSocialSignIn();
                renderProps.onClick();
              }}
              disabled={disabledProviders.includes('google')}
              className="googleLogin fullWidth"
              sx={{ mb: 2 }}
            >
              Sign up with Google
            </RoundedButton>
          )}
          onSuccess={handleSuccessResponseFromGoogle}
          onFailure={handleErrorResponseFromGoogle}
          cookiePolicy={'single_host_origin'}
        />
      </Box>

      <ConfirmSignUpModal
        open={socialModalOpen}
        defaultValues={socialData}
        onClosed={resetSocialSignIn}
      />
    </SignUpForRoomLayout>
  );
}
