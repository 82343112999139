import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectUser } from '../../../../../store/slice/common/selectors';
import { apiGet, apiPost, apiPut } from '../../../../../utils/api';
import { roomsActions as actions } from './index';
import { selectRoom, selectRoomDetails } from './selectors';
import { Room } from 'types/RoomInfo';
import { UserInfo } from 'types/UserInfo';
import { PayloadAction } from '@reduxjs/toolkit';
import appRoute from 'utils/appRoute';

function* setRoom() {
  const roomInfo = yield select(selectRoom);
  const room: Room = roomInfo.room[0];
  try {
    if (room.vacationType !== 'hotel') {
      const transferDetail = yield call(
        apiGet,
        `rooms/${room.id}/transferdetail`,
      );
      yield put(actions.setTransferDetail(transferDetail));
      return;
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(actions.toggleLoader({ isLoading: false }));
  }
}

function* updateBookingInfo(action) {
  const roomInfo = yield select(selectRoom);
  const room = roomInfo.room[0];
  const updatedTraveler = action.payload;

  try {
    yield call(apiPut, `rooms/update/${room.id}`, {
      TravelerList: [
        {
          Id: updatedTraveler.id,
          Email: updatedTraveler.email,
          Phone: updatedTraveler.phone,
        },
      ],
    });
    yield put(
      actions.updateBookingInfoSuccess('Contact information was updated'),
    );
  } catch (e) {
    yield put(
      actions.updateBookingInfoError(
        'Well this is embarrassing, that didn’t work. Please try again later or contact us for more support.',
      ),
    );
  } finally {
    yield put(actions.toggleLoader({ isLoading: false }));
  }
}

function* getRoomDetails(action) {
  const roomInfo = yield select(selectRoom);
  const roomDetails = yield select(selectRoomDetails);
  const room = roomInfo.room[0];
  const roomId = room.id;
  const { id: crmHotelId } = roomInfo.hotel[0];
  const { roomType, roomTypeCode, supplier: supplierCode } = room;
  if (roomDetails[roomId]) return;
  try {
    const isValidSupplierCode = ['bol', 'bedsonline', 'bedonline'].includes(
      supplierCode?.toLowerCase(),
    );
    const result = yield call(apiPost, 'hotel/room-type-content-detail', {
      crmHotelId,
      roomTypeFriendlyName: '',
      roomTypeCode:
        isValidSupplierCode && roomTypeCode ? roomTypeCode : roomType,
      supplierCode,
    });
    yield put(actions.setRoomDetails({ ...result, id: roomId }));
  } catch (err) {
    console.error(err);
  }
}

function* shareRoom(action) {
  const user = yield select(selectUser);
  const roomInfo = yield select(selectRoom);
  const room = roomInfo.room[0];
  const { firstName, lastName, email, level } = action.payload;

  try {
    const shareResult = yield call(apiPost, `roomaccess/create-invitation`, {
      firstName: firstName,
      lastName: lastName,
      email: email,
      accessLevel: level,
      crmRoomId: room.id,
      invitedBy: user.userId,
    });

    if (shareResult.success) {
      yield put(
        actions.shareRoomSuccess(
          `An email has been sent to ${email}. When they create an account using that email address, they will have access to this room.`,
        ),
      );
    } else {
      yield put(actions.shareRoomError(shareResult.error[0]));
    }
  } catch (e) {
    yield put(
      actions.shareRoomError(
        'Please try again or contact Guest Services for assistance.',
      ),
    );
  } finally {
    yield put(actions.toggleLoader({ isLoading: false }));
  }
}

export function* checkRoomModDateChangeAllowed(
  action: PayloadAction<{ roomId: string; history }>,
) {
  const user: UserInfo | null = yield select(selectUser);
  const { roomId, history } = action.payload;

  try {
    //for now only ChangeTravelDates is allowed
    const modAllowedCheckResult = yield call(
      apiGet,
      `modifications/${roomId}/verify-modification-allowed?modificationType=ChangeTravelDates&userId=${user?.userId}`,
    );
    if (modAllowedCheckResult.modificationAllowed) {
      history.push(appRoute.modifyReservationTravelDates(roomId));
    }

    yield put(
      actions.setRoomModDateChangeDecision({
        allowed: modAllowedCheckResult.modificationAllowed,
        rejectionReason: modAllowedCheckResult.rejectionReason,
      }),
    );
    return;
  } catch (e) {
    console.error({ e });
    yield put(
      actions.setRoomModDateChangeDecision({
        allowed: false,
      }),
    );
  } finally {
    yield put(actions.toggleLoader({ isLoading: false }));
  }
}

export function* roomsSaga() {
  yield takeLatest(actions.setRoom.type, setRoom);
  yield takeLatest(actions.updateBookingInfo.type, updateBookingInfo);
  yield takeLatest(actions.shareRoom.type, shareRoom);
  yield takeLatest(actions.getRoomDetails.type, getRoomDetails);
  yield takeLatest(
    actions.checkRoomModDateChangeAllowed.type,
    checkRoomModDateChangeAllowed,
  );
}
