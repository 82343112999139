import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';

const CardRowSkeleton = () => {
  return (
    <Box sx={{ marginBottom: '60px' }}>
      <Box ml={1.5}>
        <Skeleton variant="text" width={'30%'} height={36} />
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        p={2}
        width={'100%'}
      >
        <Stack width={'100%'}>
          <Skeleton variant="rectangular" width={'100%'} height={275} />
          <Stack spacing={0} p={2}>
            <Skeleton variant="text" width={'90%'} />
            <Skeleton variant="text" width={'90%'} />
            <Skeleton variant="text" width={'30%'} />
            <Skeleton variant="text" width={'90%'} />
          </Stack>
        </Stack>
        <Stack width={'100%'}>
          <Skeleton variant="rectangular" width={'100%'} height={275} />
          <Stack spacing={0} p={2}>
            <Skeleton variant="text" width={'90%'} />
            <Skeleton variant="text" width={'90%'} />
            <Skeleton variant="text" width={'30%'} />
            <Skeleton variant="text" width={'90%'} />
          </Stack>
        </Stack>
        <Stack width={'100%'}>
          <Skeleton variant="rectangular" width={'100%'} height={275} />
          <Stack spacing={0} p={2}>
            <Skeleton variant="text" width={'90%'} />
            <Skeleton variant="text" width={'90%'} />
            <Skeleton variant="text" width={'30%'} />
            <Skeleton variant="text" width={'90%'} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CardRowSkeleton;
