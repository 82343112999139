import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import fileDownload from 'js-file-download';
import { commonActions as actions } from './index';
import { GroupInfo } from 'types/GroupInfo';
import { GroupDetails, UserDetails } from 'types/GroupLeaderDetails';
import { GuestList } from 'types/RoomInfo';
import { UserInfo } from 'types/UserInfo';
import { IdToRole, UserTripsInfo } from 'types/UserTripsInfo';
import { apiGetFile, apiGet, apiPost } from 'utils/api';
import { apiSVGet, apiSVPost, SVResponse } from 'utils/apiSV';
import { notify } from 'utils/misc';
import { getUserInfo, isGuest, isLeader, isSalesLead } from 'utils/userHelper';
import {
  selectGroups,
  selectRoomIdToRole,
  selectUser,
  selectUserDetails,
  selectUserTrips,
} from './selectors';
import { UpdateRSVPRequestDTO } from 'types/RSVPinfo';
import { PayloadAction } from '@reduxjs/toolkit';

export function* loadLocalUserInfo() {
  const user = getUserInfo();
  yield put(actions.loadedUser(user));
}

export function* fetchUserRelatedData() {
  const user = yield select(selectUser);
  if (!user) return;
  try {
    yield call(fetchUserTrips);
    if (isGuest(user) || isLeader(user)) {
      yield call(loadTrips);
    }
    if (isLeader(user) || isSalesLead(user)) {
      yield put(actions.fetchUserDetails());
    }
  } catch (e) {
    console.error(e);
  }
}

export function* fetchUserDetails() {
  const user = yield select(selectUser);
  if (!user) return;

  try {
    const response1: SVResponse<UserDetails> = yield call(
      apiSVGet,
      'Auth/GetUserDetails',
    );
    if (!response1.isSuccess) {
      throw new Error(response1.message || 'GetUserDetails Error');
    }
    const userDetails = response1.data;
    yield put(actions.setUserDetails(userDetails));
  } catch (e) {
    console.error(e);
    notify('', e instanceof Error ? e.message : 'Unexpected Error', 'danger');
  }
}

export function* fetchGroupDetails() {
  const userDetails: UserDetails = yield select(selectUserDetails);
  if (!userDetails?.groupId) return;

  try {
    // get GroupDetails
    const response2: SVResponse<GroupDetails> = yield call(
      apiSVPost,
      'GroupManagement/GroupDetails',
      { groupId: userDetails.groupId, userId: userDetails.id },
    );

    if (!response2.isSuccess) {
      throw new Error(response2.message || 'GroupDetails Error');
    }
    const groupDetails = response2.data;
    yield put(actions.setGroupDetails(groupDetails));
  } catch (e) {
    console.error(e);
    notify('', e instanceof Error ? e.message : 'Unexpected Error', 'danger');
  }
}

export function* fetchGroupInfo() {
  const userDetails: UserDetails = yield select(selectUserDetails);
  if (!userDetails?.groupId) return;

  try {
    const groupInfo: GroupInfo = yield call(
      apiGet,
      `groups/${userDetails.groupId}`,
    );
    yield put(actions.setGroupInfo(groupInfo));
  } catch (e) {
    console.error(e);
    notify('', e instanceof Error ? e.message : 'Unexpected Error', 'danger');
  }
}

export function* fetchUserTrips() {
  const user = yield select(selectUser);
  if (!user) return;
  try {
    const userTrips = yield call(apiGet, `user/userTrips/${user.userId}`);
    if (!userTrips.isError) {
      let roomIdToRole: IdToRole = {};
      userTrips.data.forEach(trip => {
        trip.roomIds.forEach(r => {
          roomIdToRole[r.roomID.toLowerCase()] = r.userRoomRole;
        });
      });

      yield put(actions.setRoomIdToRole(roomIdToRole));
      yield put(actions.loadedUserTrips(userTrips.data));
    }
    // This is a catch-all for if a guest or leader somehow has no rooms.
    if ((isGuest(user) || isLeader(user)) && !userTrips.data.length) {
      notify(
        'Error',
        'We are unable to load your trips at this time. Please reach out to Guest Services at guestservices@destify.com',
        'danger',
      );
    }
  } catch (e) {
    console.error(e);
  }
}

export function* getUserRoomRole(action) {
  const roomIdToRole = yield select(selectRoomIdToRole);
  const roomId: string = action.payload;

  try {
    if (roomIdToRole.hasOwnProperty(roomId.toLowerCase())) {
      yield put(actions.setUserRoomRole(roomIdToRole[roomId.toLowerCase()]));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* loadTrips() {
  const userTrips = yield select(selectUserTrips);
  if (!userTrips) return;

  try {
    // Initialize an empty object to store grouped rooms
    let groupedRooms = {};

    // Get unique group IDs from userTrips
    const groupIds = userTrips.reduce(
      (arr, userTrip: UserTripsInfo) =>
        arr.includes(userTrip.groupId) ? arr : [...arr, userTrip.groupId],
      [],
    );

    // Create an array of API calls to fetch wedding information for each group
    const weddingCalls = groupIds.map((groupId: string) =>
      call(apiGet, `groups/${groupId.toLowerCase()}/wedding`),
    );

    // Initialize an array to store unique room IDs
    let roomIds: string[] = [];
    userTrips.forEach((userTrip: UserTripsInfo) => {
      roomIds = [...roomIds, ...userTrip.roomIds.map(d => d.roomID)];
    });

    // Remove duplicates from roomIds array to make it unique
    roomIds = roomIds.reduce(
      (arr, s) => (arr.includes(s) ? arr : [...arr, s]),
      [],
    );

    // Convert roomIds and groupIds arrays to comma-separated lowercase strings
    const roomIdsStr = roomIds.join(',').toLowerCase();
    const groupIdsStr = groupIds.join(',').toLowerCase();

    // Make API calls to fetch data for groups, rooms, and wedding info in parallel
    let [groups, rooms, ...weddingInfo] = yield all([
      groupIdsStr
        ? call(apiGet, 'groups', {}, { groupIds: groupIdsStr })
        : null,
      roomIdsStr ? call(apiGet, 'rooms', {}, { roomIds: roomIdsStr }) : null,
      ...weddingCalls,
    ]);

    // Extract groupInfo and roomInfo from the API responses (if available)
    groups = (groups && groups.groupInfo) || [];
    rooms = (rooms && rooms.roomInfo) || [];

    // Initialize an array to store hotel IDs
    let hotelIDs: string[] = [];
    groups.forEach(group => {
      hotelIDs = hotelIDs.concat(group.hotelIDs);
    });

    // Fetch resort images if there are hotel IDs to fetch
    let resortImages = [];
    if (hotelIDs.length > 0) {
      resortImages = yield call(
        apiGet,
        `resorts/GetImagesByHotelId?hotelIds=${hotelIDs.join(',')}`,
        {},
      );
    }

    // Add weddingInfo to respective groups and initialize groupedRooms
    groupIds.forEach((groupId: string, index: number) => {
      groups[index]['weddingInfo'] = weddingInfo[index];
      groupedRooms[groupId.toLowerCase()] = [];
    });

    // Group rooms by their associated group IDs
    rooms.forEach((room: any) => {
      room.group[0].id &&
        groupedRooms.hasOwnProperty(room.group[0].id.toLowerCase()) &&
        groupedRooms[room.group[0].id.toLowerCase()].push(room);
    });

    // Combine groups with their grouped rooms
    groups = groups.map((group: GroupInfo) => {
      if (group.groupId && groupedRooms.hasOwnProperty(group.groupId)) {
        return {
          ...group,
          rooms: groupedRooms[group.groupId],
        };
      }
      return group;
    });

    // Map resort images to groups based on matching hotel IDs
    groups = groups.map((group: GroupInfo) => ({
      ...group,
      resortImages: resortImages
        .filter(item => group.hotelIDs.includes(item.key))
        .map(item => ({
          hotelId: item.key,
          images: item.value,
        })),
    }));

    // Dispatch actions to indicate successful data loading
    yield put(actions.groupsLoaded(groups));
    yield put(actions.roomsLoaded(rooms));
  } catch (e) {
    // Handle any errors that occur during the process
    console.error(e);
  }
}

export function* loadRsvps() {
  const user = yield select(selectUser);
  const groups: GroupInfo[] = yield select(selectGroups);
  if (!user || !groups.length || !isLeader(user)) return;

  const group = groups[0];

  try {
    const rsvpsDetails = yield call(
      apiGet,
      `groupleader/${group.groupId}/rsvp`,
    );
    yield put(actions.rsvpsLoaded(rsvpsDetails));
  } catch (e) {
    console.error(e);
  }
}

export function* updateRsvpStatus(action: PayloadAction<UpdateRSVPRequestDTO>) {
  try {
    const response = yield call(
      apiPost,
      `RSVPGuest/UpdateRsvpGuestStatus`,
      action.payload,
    );
    if (!response.isError) {
      yield put(actions.setLoading(false));
      return;
    }
  } catch (e) {
    console.error(e);
  }
  // reload all if updating was not successful
  // yield put(actions.loadRsvps());
}

export function* loadGuestList() {
  const user = yield select(selectUser);
  const groups = yield select(selectGroups);
  if (!user || !groups.length) return;
  const group = groups[0];
  try {
    const data: GuestList = yield call(
      apiGet,
      `groupleader/${group.groupId}/guestlist`,
    );
    yield put(actions.guestListLoaded(data));
  } catch (e) {
    console.error(e);
  }
}

export function* downLoadGuestList() {
  const user = yield select(selectUser);
  const groups = yield select(selectGroups);
  if (!user || !groups.length) return;
  const group: GroupInfo = groups[0];
  try {
    const data = yield call(
      apiGetFile,
      `groupleader/${group.groupId}/export-guestlist`,
    );
    const fileName = `Guest List [${group.groupName}].xlsx`;
    fileDownload(data, fileName);
    yield put(actions.guestListDownLoaded(fileName));
  } catch (e) {
    console.error(e);
  }
}

export function* loadWeddingDetails() {
  const user: UserInfo = yield select(selectUser);
  const groups: GroupInfo[] = yield select(selectGroups);
  if (!user || !groups.length) return;
  const group = groups[0];
  try {
    const ids = {
      UserId: user.userId,
      GroupId: group.groupId,
    };
    const weddingDetails = yield call(
      apiPost,
      `groupleader/wedding-details`,
      ids,
    );

    yield put(actions.setWeddingDetails(weddingDetails));
  } catch (e) {
    console.error(e);
  }
}

export function* updateRoomInfo(action) {
  const roomId: string = action.payload;
  const rooms = yield call(apiGet, 'rooms', {}, { roomIds: roomId });
  const roomInfo = rooms.roomInfo[0] ?? null;
  if (roomInfo) {
    yield put(actions.roomInfoUpdated(roomInfo));
  }
}

export function* loadTopRecommendedResorts() {
  try {
    const response = yield call(apiSVGet, `Resort/TopRecommendedResort`);
    if (response.isSuccess)
      yield put(actions.setTopRecommendedResorts(response.data));
  } catch (e) {
    yield put(actions.setTopRecommendedResorts([]));
    console.error(e);
  }
}

async function loadResorts(hotelIds) {
  try {
    const response = await apiSVGet(
      `Resort/GetRecommendedResortDetails?${hotelIds
        .map(
          (id, idx) =>
            `PtidHotelIds=${id}${idx < hotelIds.length - 1 ? '&' : ''}`,
        )
        .join('')}`,
      {},
    );

    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
}

export function* loadUserRecommendedResorts() {
  const userDetails: UserDetails = yield select(selectUserDetails);
  if (!userDetails) return;

  const { weddingDetailsId } = userDetails;

  try {
    const response = yield call(
      apiSVGet,
      `SalesLeadUpsells`,
      {},
      {
        upsellCategory: 'RecommendedResorts',
        SalesLeadWeddingDetailsRecordId: weddingDetailsId,
      },
    );
    let resorts = [];

    if (response && response.length > 0) {
      const hotelIds = response.map(item => item.upsellRecordItemValue);
      resorts = yield call(loadResorts, hotelIds);
    }

    yield put(actions.setUserRecommendedResorts(resorts));
  } catch (e) {
    yield put(actions.setUserRecommendedResorts([]));
    console.error(e);
  }
}
export function* loadFavoriteResorts() {
  try {
    const response = yield call(apiSVGet, `Resort/FavoriteResortList`);
    if (response.isSuccess)
      yield put(actions.setFavoriteResorts(response.data));
  } catch (e) {
    console.error(e);
    notify(
      '',
      'An error occurred while trying to load your favorite resorts. If this issue persists, please contact Guest Services at guestservices@destify.com',
      'danger',
    );
  }
}

export function* commonSaga() {
  // yield takeLatest(actions.fetchUser.type, fetchUser);
  yield takeLatest(actions.getUserRoomRole.type, getUserRoomRole);
  yield takeLatest(actions.fetchUserRelatedData.type, fetchUserRelatedData);
  yield takeLatest(actions.fetchUserDetails.type, fetchUserDetails);
  yield takeLatest(actions.setUserDetails.type, fetchGroupDetails);
  yield takeLatest(actions.setUserDetails.type, fetchGroupInfo);
  yield takeLatest(actions.fetchGroupDetails.type, fetchGroupDetails);
  yield takeLatest(actions.fetchGroupInfo.type, fetchGroupInfo);
  yield takeLatest(actions.fetchUserTrips.type, fetchUserTrips);
  yield takeLatest(actions.loadTrips.type, loadTrips);
  yield takeLatest(actions.loadWeddingDetails.type, loadWeddingDetails);
  yield takeLatest(actions.loadLocalUser.type, loadLocalUserInfo);
  yield takeLatest(
    actions.loadUserRecommendedResorts.type,
    loadUserRecommendedResorts,
  );
  yield takeLatest(actions.loadRsvps.type, loadRsvps);
  yield takeLatest(actions.updateRsvpStatus.type, updateRsvpStatus);
  yield takeLatest(actions.groupsLoaded.type, loadGuestList);
  yield takeLatest(actions.groupsLoaded.type, loadRsvps);
  yield takeLatest(actions.groupsLoaded.type, loadWeddingDetails);
  yield takeLatest(actions.updateRoomInfo.type, updateRoomInfo);
  yield takeLatest(actions.downLoadGuestList.type, downLoadGuestList);
  yield takeLatest(
    actions.loadTopRecommendedResorts.type,
    loadTopRecommendedResorts,
  );
  yield takeLatest(actions.loadFavoriteResorts.type, loadFavoriteResorts);
}
