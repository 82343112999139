import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CardRowSkeleton from 'app/components/Skeletons/CardRowSkeleton';
import { Layout } from 'app/SalesView/Layout';
import { selectUserDetails } from 'app/SalesView/pages/Ptid/SalesLead/slice/selectors';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Countdown from 'react-countdown';
import Carousel from 'react-multi-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import {
  selectFavoriteResorts,
  selectRooms,
  selectTopRecommendedResorts,
  selectUserRecommendedResorts,
  selectWeddingInfo,
} from 'store/slice/common/selectors';

import {
  selectLoading,
  selectUser,
} from '../../../../store/slice/common/selectors';
import { UserDetails } from '../../../../types/GroupLeaderDetails';
import { UserInfo } from '../../../../types/UserInfo';
import appRoute from '../../../../utils/appRoute';
import {
  isGuest,
  isLeader,
  isSalesLead,
  isSalesLeadAny,
  isSalesLeadNoAccount,
} from '../../../../utils/userHelper';
import { RationalWrapper } from '../../../components/CardMediaRational';
import { IconDestifyLogo } from '../../../components/Icons/IconDestifyLogo';
import { IconHeart } from '../../../components/Icons/IconHeart';
import { FadeInContainer } from '../../../components/Misc/FadeInContainer';
import { RoundedButton } from '../../../components/RoundedButton';
import { useWeddingWebsiteSlice } from '../../../GuestView/pages/WeddingWebsite/slice';
import { useSalesLeadSlice } from 'app/SalesView/pages/Ptid/SalesLead/slice';
import { selectWebsiteDetail } from '../../../GuestView/pages/WeddingWebsite/slice/selectors';
import { WebsiteDetail } from '../../../GuestView/pages/WeddingWebsite/slice/types';
import { PtidFullImageModal } from '../../../SalesView/pages/Ptid/PtidFullImageModal';
import { Resort } from '../../../SalesView/pages/Ptid/SalesLead/slice/types';
import {
  ResortCard,
  SeeMoreModal,
} from '../../../SalesView/pages/Ptid/SalesLead/StepResort';
import { selectAllData } from 'app/SalesView/pages/Ptid/SalesLead/slice/selectors';
import { faqActions } from 'app/SalesView/pages/Ptid/Support/slice';

import { ConfirmationModal } from './ConfirmationModal';
import { useLazyScroll } from 'utils/infiniteScroll';
import { useIsMobile } from 'utils/responsiveHelper';
import { QuickLinks } from 'app/components/QuickLinks';
import { useCommonSlice } from 'store/slice/common';
import { selectUserTrips } from 'store/slice/common/selectors';
import Reservations from './components/Reservations';
import { responsive } from './constants';

interface Props {}

export function Home(props: Props) {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { actions } = useWeddingWebsiteSlice();
  const salesActions = useSalesLeadSlice().actions;

  const loading = useSelector(selectLoading);
  const userDetails: UserDetails = useSelector(selectUserDetails);
  const user: UserInfo = useSelector(selectUser);
  const rooms = useSelector(selectRooms);
  const userTrips = useSelector(selectUserTrips);

  const websiteDetail: WebsiteDetail = useSelector(selectWebsiteDetail);
  const commonActions = useCommonSlice().actions;
  const weddingInfo = useSelector(selectWeddingInfo);

  const topRecommendedResorts = useSelector(selectTopRecommendedResorts);
  const favoriteResorts = useSelector(selectFavoriteResorts);
  const userRecommendedResorts = useSelector(selectUserRecommendedResorts);
  const allData = useSelector(selectAllData);
  const [showSeeMoreModal, setShowSeeMoreModal] = useState(false);
  const [seeMoreResort, setSeeMoreResort]: [Resort, any] = useState(null);
  const [fullImageList, setFullImageList] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedResort, setSelectedResort]: [Resort, any] = useState(null);
  const [selectedResortType]: [
    'TopRecommendationResort' | 'FavoriteResort',
    any,
  ] = useState(null);

  useEffect(() => {
    if (userTrips) dispatch(commonActions.loadTrips());
    else dispatch(commonActions.fetchUserTrips());
  }, [userTrips]);

  useEffect(() => {
    if (isSalesLeadAny(user)) {
      dispatch(commonActions.loadTopRecommendedResorts());
    }
    if (isSalesLead(user)) {
      dispatch(commonActions.loadFavoriteResorts());
    }
  }, [user]);

  useEffect(() => {
    dispatch(faqActions.fetchAgentDetails());
  }, []);

  const roomsSortedByPastDue = useMemo(() => {
    if (!Array.isArray(rooms) || !rooms.length) return [];
    return [...rooms].sort((r1, r2) => {
      const a = r1.room[0];
      const b = r2.room[0];
      if (a.roomStatus === 'PastDue' && b.roomStatus !== 'PastDue') {
        return -1;
      }
      if (a.roomStatus !== 'PastDue' && b.roomStatus === 'PastDue') {
        return 1;
      }
      return 0;
    });
  }, [rooms]);

  const paymentDetails = useMemo(() => {
    if (!roomsSortedByPastDue.length) return null;
    const targetRoom =
      roomsSortedByPastDue.find(({ room }) => room[0].roomStatus === 'PastDue')
        ?.room?.[0] ?? null;
    if (!targetRoom) return null;
    return {
      roomId: targetRoom.id,
    };
  }, [roomsSortedByPastDue]);

  useEffect(() => {
    if (!websiteDetail && (isLeader(user) || isGuest(user))) {
      dispatch(actions.loadWebsiteDetail());
    }
  }, [weddingInfo, websiteDetail, user]);

  useEffect(() => {
    if (isSalesLead(user) && userDetails) {
      dispatch(commonActions.loadUserRecommendedResorts());
    }
  }, [userDetails]);

  const favoriteResortIdList = useMemo(() => {
    if (!favoriteResorts) return [];
    return favoriteResorts.map(item => item.resortId);
  }, [favoriteResorts]);

  const handleSeeMoreClick = (resort: Resort) => {
    setShowSeeMoreModal(true);
    setSeeMoreResort(resort);
  };

  const handleResortConfirmation = () => {
    const payload = {
      resortId: selectedResort.resortId,
      redirectSrc: selectedResortType,
      history: history,
    };

    dispatch(salesActions.doPreselectResort(payload));
  };

  const websiteHasEditableTheme = websiteDetail?.theme
    ?.toLowerCase()
    ?.includes('border');

  const seeMoreModal = useMemo(() => {
    return showSeeMoreModal ? (
      <SeeMoreModal
        item={seeMoreResort}
        open={showSeeMoreModal}
        onClose={() => {
          setShowSeeMoreModal(false);
          setSeeMoreResort(null);
        }}
        onCancel={() => {
          setShowSeeMoreModal(false);
          setSeeMoreResort(null);
        }}
      />
    ) : null;
  }, [showSeeMoreModal, seeMoreResort]);

  const confirmationModal = useMemo(() => {
    return showConfirmationModal ? (
      <ConfirmationModal
        resort={selectedResort}
        resortType={selectedResortType}
        open={showConfirmationModal}
        onClose={() => {
          setShowConfirmationModal(false);
          setSelectedResort(null);
        }}
        onContinue={() => {
          setShowConfirmationModal(false);
          handleResortConfirmation();
        }}
      />
    ) : null;
  }, [showConfirmationModal, selectedResort, selectedResortType]);

  let anticipatedWeddingDate;
  switch (true) {
    case isSalesLead(user):
      anticipatedWeddingDate = userDetails?.anticipatedWeddingDate;
      break;
    case isSalesLeadNoAccount(user):
      anticipatedWeddingDate = allData?.anticipatedWeddingDate;
      break;
    default:
      anticipatedWeddingDate = weddingInfo?.weddingDate;
  }

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-evenly'}
        whiteSpace={'nowrap'}
        gap={2}
        py={1}
        px={'1rem'}
        sx={{ width: ['90vw', '90vw', '75vw'] }}
      >
        <Box>
          <IconDestifyLogo />
        </Box>
        <Stack direction={'column'} alignItems={'center'}>
          <Typography variant={'body1'} fontWeight={700} fontSize={18}>
            {days}
          </Typography>
          <Typography variant={'body2'} fontSize={12}>
            days
          </Typography>
        </Stack>
        <Stack direction={'column'} alignItems={'center'}>
          <Typography variant={'body1'} fontWeight={700} fontSize={18}>
            {hours}
          </Typography>
          <Typography variant={'body2'} fontSize={12}>
            hours
          </Typography>
        </Stack>
        <Stack direction={'column'} alignItems={'center'}>
          <Typography variant={'body1'} fontWeight={700} fontSize={18}>
            {minutes}
          </Typography>
          <Typography variant={'body2'} fontSize={12}>
            minutes
          </Typography>
        </Stack>
        <Stack direction={'column'} alignItems={'center'}>
          <Typography variant={'body1'} fontWeight={700} fontSize={18}>
            {seconds}
          </Typography>
          <Typography variant={'body2'} fontSize={12}>
            seconds
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const handleLikeClick = useCallback(
    (resort: Resort, index: number) => {
      if (favoriteResortIdList.includes(resort.resortId)) {
        dispatch(salesActions.removeFavoriteResort(resort.ptidAdminHotelId));
      } else {
        dispatch(salesActions.addFavoriteResort(resort.ptidAdminHotelId));
      }
    },
    [salesActions, dispatch, favoriteResortIdList],
  );
  const ResortCarousel = ({
    resortList,
    favorite,
  }: {
    resortList: Resort[];
    favorite: boolean;
  }) => {
    const isMobile = useIsMobile();
    const isTablet = useIsMobile('md');
    const initialVisibleResorts = isMobile ? 3 : isTablet ? 6 : 9;
    const [visibleResorts, loadNext] = useLazyScroll(
      resortList,
      initialVisibleResorts,
    );

    return (
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side.
        infinite={false}
        autoPlay={false}
        keyBoardControl={true}
        containerClass="carousel-container"
        itemClass="carousel-item"
        beforeChange={loadNext}
      >
        {visibleResorts.map((r, i) => {
          if (i > visibleResorts.length) return <></>;
          return (
            <ResortCard
              key={`${r.resortName}--${r.resortVenueId}`}
              index={i}
              resort={{
                ...r,
                isFavorite: favoriteResortIdList.includes(r.resortId),
              }}
              onSeeMoreClick={handleSeeMoreClick}
              onExpand={() => setFullImageList(r.images)}
              isFavoriteResort={favoriteResortIdList.includes(r.resortId)}
              theme={theme}
              onLikeClick={handleLikeClick}
              hideLike={!user}
            />
          );
        })}
      </Carousel>
    );
  };

  return (
    <Layout
      showProgress={false}
      isHomePage={true}
      title="Destify - Destination Weddings"
    >
      {loading ? (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent="center"
          pt={6}
          pb={6}
        >
          <img width={120} src={'/img/loader.svg'} alt="Loading" />
        </Box>
      ) : (
        <FadeInContainer>
          <Banner theme={theme}>
            <BannerInnerContainer>
              <Typography
                variant={'h6'}
                sx={{
                  textTransform: 'uppercase',
                  fontSize: {
                    md: 30,
                    sm: 20,
                  },
                }}
                fontFamily={'"Nunito", sans-serif'}
                fontWeight={700}
                lineHeight={1.3}
                marginTop={'1rem'}
              >
                {!!user &&
                  `Welcome Back ${user.firstName} ${user.lastName || ''}`}
              </Typography>

              {isSalesLeadAny(user) && (
                <Typography
                  variant={'h6'}
                  sx={{
                    fontSize: {
                      md: 30,
                      sm: 20,
                    },
                  }}
                  fontFamily={'"Nunito", sans-serif'}
                  fontWeight={700}
                  lineHeight={1.3}
                >
                  <span>
                    Say <em>"I Do"</em> in Paradise
                  </span>
                  {/* Commenting this out for now */}
                  {/* {isSalesLead(user) ? (
                    `Continue where you left off...`
                  ) : (
                    <span>
                      Say <em>"I Do"</em> in Paradise
                    </span>
                  )} */}
                </Typography>
              )}
              {/* Commenting this out for now */}
              {/* {isSalesLeadAny(user) && (
                <Box
                  margin={'auto'}
                  sx={{ marginTop: ['2.5vh', '2vh', '2.5vh'] }}
                >
                  <Link
                    to={user ? appRoute.ptid() : appRoute.salesLead()}
                    data-testid="home-button-keep-exploring"
                  >
                    <RoundedButton type="submit">
                      {user ? 'Continue' : 'Get Started'}
                    </RoundedButton>
                  </Link>
                </Box>
              )} */}

              {paymentDetails?.roomId && (
                <Box
                  sx={{
                    width: '200px',
                    textAlign: 'center',
                    margin: 'auto',
                  }}
                >
                  <Stack alignItems={'center'} gap={1}>
                    <Link to={`/rooms/${paymentDetails.roomId}`}>
                      <RoundedButton className="red">
                        Room Past Due
                      </RoundedButton>
                    </Link>
                    <Typography sx={{ whiteSpace: 'nowrap' }}>
                      Please make payment in My Rooms page
                    </Typography>
                  </Stack>
                </Box>
              )}

              {anticipatedWeddingDate && !isGuest(user) && (
                <AnticipatedWeddingDateCountdown
                  sx={{ width: ['90vw', '90vw', '75vw'] }}
                >
                  <Box className={'title'}>
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                      <IconHeart />
                      <Typography variant={'body1'} fontSize={15}>
                        {isLeader(user)
                          ? 'Countdown to I Do'
                          : 'Anticipated wedding date'}
                      </Typography>
                    </Stack>
                  </Box>

                  <Countdown
                    key={Math.random()}
                    date={anticipatedWeddingDate}
                    renderer={renderer}
                  />
                </AnticipatedWeddingDateCountdown>
              )}
            </BannerInnerContainer>
          </Banner>
          <ContentWrapper>
            {!isGuest(user) && <QuickLinks />}

            {isSalesLeadAny(user) && (
              <>
                {user && (
                  <>
                    {!userRecommendedResorts && <CardRowSkeleton />}
                    {userRecommendedResorts &&
                      userRecommendedResorts.length > 0 && (
                        <Box sx={{ marginBottom: '60px' }}>
                          <Box px={2} mb={2}>
                            <Typography variant={'h4'}>
                              Recommended for You
                            </Typography>
                            <Divider />
                          </Box>
                          <Box>
                            <ResortCarousel
                              resortList={userRecommendedResorts}
                              favorite={true}
                            />
                          </Box>
                        </Box>
                      )}
                  </>
                )}

                {/* <ContentSection>
                  <Box px={2} mb={2}>
                    <Typography variant={'h4'}>Contact Us</Typography>
                    <Divider />
                  </Box>
                  <Box
                    sx={{
                      bgcolor: '#ebf8f9',
                      height: 'auto',
                      padding: 2,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Box
                      my={1}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Box
                        sx={{
                          marginLeft: 2,
                          marginRight: 2,
                          justifyContent: 'center',
                          flexDirection: 'row',
                        }}
                      >
                        <IconPhone height={70} width={39} />
                      </Box>
                      <Box
                        sx={{
                          textAlign: 'justify',
                          marginLeft: 1,
                          display: 'flex',
                          flex: 1,
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: 'bold', textAlign: 'left' }}
                        >
                          Speak to one of our destination wedding experts.
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignSelf: 'flex-end',
                        alignItems: 'center',
                        marginRight: 2,
                        height: '60px',
                        [theme.breakpoints.down('md')]: {
                          width: '100%',
                          justifyContent: 'center',
                        },
                      }}
                    >
                      <RoundedButton onClick={handleCall}>
                        <Typography>Schedule a Free Call</Typography>
                      </RoundedButton>
                    </Box>
                  </Box>
                </ContentSection> */}
                {topRecommendedResorts === null && <CardRowSkeleton />}
                {topRecommendedResorts && topRecommendedResorts.length > 0 && (
                  <Box sx={{ marginBottom: '60px', marginTop: '3rem' }}>
                    <Box px={2} mb={2}>
                      <Typography variant={'h4'}>
                        Destify&apos;s Favorites
                      </Typography>
                      <Divider />
                    </Box>
                    <Box>
                      <ResortCarousel
                        resortList={topRecommendedResorts}
                        favorite={false}
                      />
                    </Box>
                  </Box>
                )}

                {user && (
                  <>
                    {!favoriteResorts && <CardRowSkeleton />}
                    {favoriteResorts && favoriteResorts.length > 0 && (
                      <ContentSection>
                        <Box px={2} mb={2}>
                          <Typography variant={'h4'}>Your Favorites</Typography>
                          <Divider />
                        </Box>
                        <Box>
                          <ResortCarousel
                            resortList={favoriteResorts}
                            favorite={true}
                          />
                        </Box>
                      </ContentSection>
                    )}
                  </>
                )}
              </>
            )}
            {isGuest(user) && !isLeader(user) && (
              <>
                <ContentSection>
                  <Box px={2} mb={4}>
                    <Typography variant={'h4'}>Your Events</Typography>
                    <Divider />
                  </Box>
                  <Box
                    px={2}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: [
                        '1fr',
                        '1fr 1fr 1fr',
                        '1fr 1fr 1fr',
                      ],
                      gap: '16px',
                      [theme.breakpoints.up('sm')]: {
                        gap: '32px',
                      },
                    }}
                  >
                    <HomeMenuItem
                      onClick={() => {
                        websiteDetail?.websiteUrl
                          ? window.open(websiteDetail.websiteUrl, '_blank')
                          : history.push(appRoute.weddingWebsitePage());
                      }}
                    >
                      <RationalWrapper ratio={1.6}>
                        <CardMedia
                          component="img"
                          image={'/img/home/event.webp'}
                          alt={websiteDetail?.groupName}
                          sx={{ width: '100%' }}
                        />
                      </RationalWrapper>
                      <CardContent
                        sx={{ '&:last-child': { paddingBottom: 2 } }}
                      >
                        <Typography className={'title'} variant={'h4'}>
                          {websiteDetail?.groupName}
                        </Typography>
                      </CardContent>
                    </HomeMenuItem>
                  </Box>
                </ContentSection>
                <ContentSection>
                  <Reservations items={roomsSortedByPastDue} />
                </ContentSection>
              </>
            )}
            {isLeader(user) && (
              <>
                <ContentSection>
                  <Box px={2} mb={4}>
                    <Typography variant={'h4'}>Your Wedding</Typography>
                    <Divider />
                  </Box>
                  <Box
                    px={2}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr 1fr',
                      gap: '16px',
                      [theme.breakpoints.up('sm')]: {
                        gap: '32px',
                      },
                    }}
                  >
                    <HomeMenuItem
                      onClick={() => {
                        history.push(appRoute.rsvpsPage());
                      }}
                    >
                      <RationalWrapper ratio={1.6}>
                        <CardMedia
                          component="img"
                          image={'/img/home/rsvps.webp'}
                          alt={'RSVPs'}
                          sx={{ width: '100%' }}
                        />
                      </RationalWrapper>
                      <CardContent
                        sx={{
                          '&:last-child': { paddingBottom: 1, paddingTop: 1 },
                        }}
                      >
                        <Typography className={'title'} variant={'h4'}>
                          RSVPs
                        </Typography>
                      </CardContent>
                    </HomeMenuItem>
                    <HomeMenuItem
                      onClick={() => {
                        history.push(appRoute.guestListPage());
                      }}
                    >
                      <RationalWrapper ratio={1.6}>
                        <CardMedia
                          component="img"
                          image={'/img/home/guest-list.webp'}
                          alt={'Gust List'}
                          sx={{ width: '100%' }}
                        />
                      </RationalWrapper>
                      <CardContent
                        sx={{
                          '&:last-child': { paddingBottom: 1, paddingTop: 1 },
                        }}
                      >
                        <Typography className={'title'} variant={'h4'}>
                          Guest List
                        </Typography>
                      </CardContent>
                    </HomeMenuItem>
                    {!websiteDetail?.websiteUrl || websiteHasEditableTheme ? (
                      <HomeMenuItem
                        onClick={() => {
                          history.push(appRoute.weddingWebsitePage());
                        }}
                      >
                        <RationalWrapper ratio={1.6}>
                          <CardMedia
                            component="img"
                            image={'/img/home/wedding-website.webp'}
                            alt={'Wedding Website'}
                            sx={{ width: '100%' }}
                          />
                        </RationalWrapper>
                        <CardContent
                          sx={{
                            '&:last-child': { paddingBottom: 1, paddingTop: 1 },
                          }}
                        >
                          <Typography className={'title'} variant={'h4'}>
                            Website
                          </Typography>
                        </CardContent>
                      </HomeMenuItem>
                    ) : (
                      ''
                    )}
                  </Box>
                </ContentSection>
                <ContentSection>
                  <Reservations items={roomsSortedByPastDue} />
                </ContentSection>
              </>
            )}
          </ContentWrapper>
        </FadeInContainer>
      )}

      {fullImageList.length > 0 && (
        <PtidFullImageModal
          fullImageList={fullImageList}
          onClose={() => setFullImageList([])}
        />
      )}

      {confirmationModal}
      {seeMoreModal}
    </Layout>
  );
}

const Banner = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '200px',
  marginBottom: '62px',
  backgroundImage: 'url("/img/home/banner.webp")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  [theme.breakpoints.up('md')]: {
    height: '250px',
  },
  '&::before': {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    content: '""',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
}));

const BannerInnerContainer = styled(Box)({
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '1200px',
  height: '100%',
  margin: '0 auto',
  padding: '1rem',
});

const HomeMenuItem = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  '.title': {
    fontSize: '12px',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
  },
}));

const ContentWrapper = styled(Box)({
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '1rem',
});

const ContentSection = styled(Box)({
  marginBottom: '60px',
});

const AnticipatedWeddingDateCountdown = styled(Box)({
  position: 'absolute',
  zIndex: 99,
  bottom: '-46px',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  minWidth: '280px',
  maxWidth: 'fit-content',
  transform: 'translateX(-50%)',
  borderRadius: '16px',
  backgroundColor: '#ffffff',
  boxShadow: '0 0 6px rgba(27, 38, 51, 0.1), 0 0 4px rgba(27, 38, 51, 0.05)',
  '& .title': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    backgroundColor: '#e3f7fa',
  },
});
