import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { roomsSaga } from './saga';
import { RoomsState, RoomDetail } from './types';

export const initialState: RoomsState = {
  loading: false,
  bookingUpdateLoading: false,
  shareLoading: false,
  resultStatus: null,
  resultTitle: null,
  resultMessage: null,
  depositPending: false,
  roomDetails: {},
  roomModDateChangeDisallowedReason: null,
  roomModDateChangeAllowed: null,
};

const slice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    setRoom(state, action: PayloadAction<any>) {
      state.loading = true;
      state.room = action.payload;
    },
    setTransferDetail(state, action: PayloadAction<any>) {
      state.transfer = action.payload;
      state.loading = false;
    },
    updateBookingInfo(state, action: PayloadAction<any>) {
      state.bookingUpdateLoading = true;
    },
    getRoomDetails(state) {},
    setRoomDetails(state, action: PayloadAction<RoomDetail & { id: string }>) {
      const { id, ...roomDetails } = action.payload;
      state.roomDetails = {
        ...state.roomDetails,
        [id]: roomDetails,
      };
    },
    updateBookingInfoSuccess(state, action: PayloadAction<any>) {
      state.resultTitle = 'The information was saved';
      state.resultMessage = action.payload;
      state.resultStatus = 'success';
      state.bookingUpdateLoading = false;
    },
    updateBookingInfoError(state, action: PayloadAction<any>) {
      state.resultTitle = 'Something happened';
      state.resultMessage = action.payload;
      state.resultStatus = 'error';
      state.bookingUpdateLoading = false;
    },
    clearResultData(state) {
      state.resultTitle = null;
      state.resultMessage = null;
      state.resultStatus = null;
    },
    shareRoom(state, action: PayloadAction<any>) {
      state.shareLoading = true;
    },
    shareRoomSuccess(state, action: PayloadAction<any>) {
      state.resultTitle = 'Invitation sent';
      state.resultMessage = action.payload;
      state.resultStatus = 'success';
      state.shareLoading = false;
    },
    shareRoomError(state, action: PayloadAction<any>) {
      state.resultTitle = 'Something happened';
      state.resultMessage = action.payload;
      state.resultStatus = 'error';
      state.shareLoading = false;
    },
    depositPendingAlert(state) {
      state.depositPending = true;
    },
    clearDepositPendingAlert(state) {
      state.depositPending = false;
    },
    checkRoomModDateChangeAllowed(
      state,
      action: PayloadAction<{ roomId: string; history }>,
    ) {
      state.roomModDateChangeAllowed = null;
      state.roomModDateChangeDisallowedReason = null;
      state.loading = true;
    },
    setRoomModDateChangeDecision(
      state,
      action: PayloadAction<{
        allowed: boolean;
        rejectionReason?: string;
      }>,
    ) {
      state.roomModDateChangeAllowed = action.payload.allowed;
      if (action.payload.rejectionReason) {
        state.roomModDateChangeDisallowedReason =
          action.payload.rejectionReason;
      }
      state.loading = false;
    },
    clearRoomModDecision(state) {
      state.roomModDateChangeAllowed = null;
      state.roomModDateChangeDisallowedReason = null;
    },
    toggleLoader(state, action: PayloadAction<{ isLoading: boolean }>) {
      state.loading = action.payload.isLoading;
    },
  },
});

export const { actions: roomsActions } = slice;

export const useRoomsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: roomsSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useRoomsSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
