import * as Sentry from '@sentry/react';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const NODE_ENV = process.env.REACT_APP_SENTRY_ENVIRONMENT;

export const configSentry = () => {
  Sentry.init({
    sendDefaultPii: true,
    dsn: SENTRY_DSN,
    environment: NODE_ENV,
    normalizeDepth: 10,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/destify/],
    integrations: [
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        networkDetailAllowUrls: [/destify/],
      }),
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: NODE_ENV === 'production' ? 0.5 : 0.1,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: NODE_ENV === 'development' ? 0 : 1.0,
    replaysOnErrorSampleRate: NODE_ENV === 'development' ? 0 : 1.0,
  });
};
